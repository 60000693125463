import React from 'react'

const RightArrow = () => {
    return <>
        <svg width="30" height="45" viewBox="0 0 45 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.0579 38.1042L28.6823 35.1422L20.1576 25.5L28.6823 15.8578L26.0579 12.8959L14.8902 25.5L26.0579 38.1042Z" fill="#576B75"/>
        </svg>
    </>
}

export default RightArrow