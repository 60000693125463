import React from "react"
import RightArrow from '../svg/right-arrow'
import SearchScreen from "../search-screen"

const Home = () => {

    return (
        <>
            <div className="ds-l-row ds-u-margin--2 ds-u-border-bottom--1 ds-u-border-left--1 ds-u-radius">
                <div className="ds-l-col--2 ds-u-border-right--2 ds-u-border-top--1 ds-u-radius">
                    <div className="ds-l-row ds-u-border-bottom--2 ds-u-radius">
                            <span><RightArrow/>Hide Fields</span>
                    </div>
                </div>

                <div className="ds-l-col--10">
                    <SearchScreen/>

                </div>
            </div>
        </>
    )
}

export default Home