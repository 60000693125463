import React from 'react';
import { TextField } from '@cmsgov/design-system';

function EmailTextField() {
  return (
    <div>
      <TextField
        defaultValue="johndoe@cms.gov"
        label="Enter Email address"
        labelClassName="ds-u-margin-top--0"
        name="single_example"
      />
    </div>
  );
}

export default EmailTextField;
