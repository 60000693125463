import {TextField} from "@cmsgov/design-system"
import React from "react"
import RenderTableData from "../components/render-table-data"
import './search-screen.scss'

const SearchScreen = () => {
    const initialData =  [{"id":1,"title":"Item1","date":"","title1":"","title2":""},{"id":2,"title":"Item2","date":"","title1":"","title2":""},{"id":3,"title":"Item3","date":"","title1":"","title2":""},{"id":4,"title":"Item4","date":"","title1":"","title2":""},{"id":5,"title":"Item5","date":"","title1":"","title2":""},{"id":6,"title":"Item6","date":"","title1":"","title2":""}]

    const tableElements = () => {
        return [
            {
                title: 'Title',
                render: function rederRow(rowData) {
                    return <span>{rowData.title}</span>;
                },
            },
            {
                title: 'Date',
                render: function rederRow(rowData) {
                    return <span>{rowData.date}</span>;
                },
            },
            {
                title: 'Title',
                render: function rederRow(rowData) {
                    return <span>{rowData.title1}</span>;
                },
            },
            {
                title: 'Title',
                render: function rederRow(rowData) {
                    return <span>{rowData.title2}</span>;
                },
            },
        ]
    }

    return <>
        <div className="ds-l-row ds-u-margin-left--2">
            <div className="ds-l-col--10">
                <TextField
                    id="querySearchId"
                    defaultValue=""
                    label="Query Data"
                    name="search"
                />
            </div>
            <div className="ds-l-col--2  ds-u-margin-top--7">
                <button className="ds-c-button ds-u-color--white ds-u-fill--gray ds-u-border--0">Search</button>
            </div>
        </div>

        <div className="ds-l-row ds-u-margin-left--2 ds-u-margin-top--6">
            <div className="ds-l-col--12">
                <button className="ds-c-button ds-u-color--gray ds-u-fill--gray-light ds-u-border--0">Refresh Data</button>
            </div>
        </div>

        <div className="ds-l-row ds-u-margin-left--2 ds-u-margin-top--6">
            <div className="ds-l-col--12">
                <span className="ds-u-font-weight--semibold">ENTRIES</span>
                <span className="ds-u-padding-left--1">({initialData.length})</span>
            </div>
        </div>
        <div className="ds-l-row ds-u-margin-left--2">
            <div className="ds-l-col--12">
                <RenderTableData cols={tableElements()} data={initialData} striped bordered={false} />
            </div>
        </div>
    </>
}

export default SearchScreen