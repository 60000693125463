import React from 'react';
import { TextField } from '@cmsgov/design-system';

function PasswordTextField() {
  return (
    <div>
      <TextField
        label="Enter Password"
        labelClassName="ds-u-margin-top--0"
        name="single_example"
      />
    </div>
  );
}

export default PasswordTextField;
