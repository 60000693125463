import React from 'react';
import PropTypes from 'prop-types'

const RenderTableData = ({ cols, data, bordered, hoverable, striped, isDark }) => {
    return (
        <>
            <table className={`table ${bordered ? 'table-bordered' : 'table-borderless'} ${hoverable && 'table-hover'} ${striped && 'table-striped'} ${isDark && 'table-dark'}`}>
                <thead className="ds-u-border-top--1 ds-u-border-bottom--1 ds-u-border--dark">
                <tr>
                    {cols.map((headerItem, index) => (
                        <th key={index}>{headerItem.title}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        {cols.map((col, key) => (
                            <td key={key}>{col.render(item)}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}

RenderTableData.propTypes = {
    cols: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    bordered: PropTypes.bool,
    hoverable: PropTypes.bool,
    striped: PropTypes.bool,
    isDark: PropTypes.bool,
}

RenderTableData.defaultProps = {
    bordered: true,
    hoverable: false,
    striped: false,
    isDark: false,
}

export default RenderTableData