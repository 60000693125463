import './login.scss'
import AcmeButton from '../components/acmebutton'
import EmailTextField from '../components/EmailTextField'
import PasswordTextField from '../components/PasswordTextField'



const Login = () => {

  return (
    <section class="ds-l-container preview__grid">
      <div className= "login-div" class="d-flex justify-content-center">
            <form class="form-signin">
              <div class="form-label-group">
                  <EmailTextField></EmailTextField>
              </div>
              <div class="form-label-group">
                  <PasswordTextField></PasswordTextField>
              </div>
              <div class="submit-button-row ds-u-color--white ds-u-text-align--center">
                  <AcmeButton> Sign In</AcmeButton>
              </div>           
            </form>
      </div>

    </section>
  )
}

export default Login
