import './App.scss'
import React, { Suspense } from 'react'
import {BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Home from './home/home'
import Login from './login/login'


function App() {

  return (
    <div>
      <BrowserRouter>
          <div className="ds-l-row content-row">
              <div className='acme-body ds-l-col--12 ds-u-margin-bottom--2'>
                          <Suspense fallback={<h1>Loading profile...</h1>}>
                              <Switch>
                                  <Route exact path="/" component={() => <Redirect to='/home' />} />
                                  <Route exact path="/home"  component={ Home } />
xx                                 <Route exact path="/login"  component={ Login } />
                              </Switch>
                          </Suspense>
                  </div>
          </div>
    </BrowserRouter>
  </div>
  );
}

export default App;
